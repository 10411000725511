var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "asyent-window",
    {
      attrs: {
        "header-actions": _vm.headerActions,
        "page-path": _vm.ae$pagePath,
        title: _vm.ae$title,
        "toolbar-actions": _vm.toolbarActions,
        widget: _vm.widget,
        "title-icon": "mdi-table-eye",
        "initial-width": "4",
        "title-icon-color": "red",
      },
      on: {
        "toolbar-action": function ($event) {
          return _vm.toolbarMethod($event)
        },
      },
    },
    [
      _c(
        "v-dialog",
        {
          attrs: {
            fullscreen: _vm.$vuetify.breakpoint.smAndDown,
            persistent: "",
            scrollable: "",
            transition: "dialog-bottom-transition",
            width: "75vw",
          },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            {
              staticClass: "pa-0 ma-0",
              staticStyle: { "border-radius": "0" },
              attrs: { color: "cardDefault", flat: "" },
            },
            [
              _c(
                "v-toolbar",
                {
                  staticClass: "mb-4",
                  attrs: { color: "primary", dark: "", "max-height": "56" },
                },
                [
                  _c("v-toolbar-title", [
                    _vm._v(
                      _vm._s(
                        _vm.isView
                          ? _vm.ae$prefixLng("catalog", "dialog.title.view")
                          : _vm.editedIndex === -1
                          ? _vm.ae$prefixLng("catalog", "dialog.title.new")
                          : _vm.ae$prefixLng("catalog", "dialog.title.edit")
                      ) + " "
                    ),
                  ]),
                  _c("v-spacer"),
                  !_vm.isView
                    ? _c("asyent-button", {
                        attrs: {
                          "button-icon": "mdi-check",
                          "button-tooltip": _vm.ae$prefixLng(
                            "catalog",
                            "dialog.button.save"
                          ),
                          dark: "",
                          loading: _vm.isSaving,
                          icon: "",
                        },
                        on: { confirmed: _vm.save },
                      })
                    : _vm._e(),
                  _c("asyent-button", {
                    attrs: {
                      "button-icon": "mdi-close",
                      "button-tooltip": _vm.ae$prefixLng(
                        "catalog",
                        "dialog.button.cancel"
                      ),
                      dark: "",
                      loading: _vm.isSaving || _vm.isDeleting,
                      icon: "",
                    },
                    on: { confirmed: _vm.close },
                  }),
                ],
                1
              ),
              _c(
                "v-card-text",
                {
                  style: {
                    "max-height": _vm.$vuetify.breakpoint.smAndUp ? "75vh" : "",
                  },
                },
                [
                  _vm.errors.length > 0
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "pb-0", attrs: { cols: "12" } },
                            [
                              _c(
                                "v-alert",
                                {
                                  attrs: { type: "error", transition: "fade" },
                                },
                                _vm._l(_vm.errors, function (message, i) {
                                  return _c(
                                    "v-list-item",
                                    {
                                      key: i,
                                      staticClass: "text-body-2",
                                      attrs: { dense: "" },
                                    },
                                    [_vm._v(" " + _vm._s(message) + " ")]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-card",
                            { attrs: { color: "cardDefault", flat: "" } },
                            [
                              !_vm.viewOnly
                                ? _c(
                                    "v-card-title",
                                    {
                                      staticClass:
                                        "pa-0 ma-0 pb-4 subtitle-2 font-weight-bold",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.ae$prefixLng(
                                              "catalog",
                                              "dialog.sections.validity"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "v-card-text",
                                { staticClass: "pa-0 ma-0 pr-4" },
                                [
                                  !_vm.viewOnly
                                    ? _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c("v-divider", {
                                                staticClass: "pt-2 pb-2",
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-row",
                                    _vm._l(_vm.segmentsIds, function (el) {
                                      return _c(
                                        "v-col",
                                        {
                                          key: el.fieldName,
                                          attrs: {
                                            cols: "12",
                                            md: "6",
                                            lg: "4",
                                          },
                                        },
                                        [
                                          _vm.fieldType(el) === "BOOLEAN"
                                            ? _c("asyent-form-boolean", {
                                                attrs: {
                                                  label: _vm.ae$lng(
                                                    el.fieldName
                                                  ),
                                                  "view-mode": _vm.viewOnly,
                                                  "form-errors": _vm.formErrors,
                                                  "form-rules":
                                                    _vm.formFieldsRules,
                                                  "is-disabled-func": function (
                                                    _
                                                  ) {
                                                    return (
                                                      _vm.isUpdate ||
                                                      _vm.isDelete
                                                    )
                                                  },
                                                  props: {},
                                                  "false-value": false,
                                                  "true-value": true,
                                                  name: el.fieldName,
                                                },
                                                model: {
                                                  value:
                                                    _vm.editedItem[
                                                      el.fieldName
                                                    ],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.editedItem,
                                                      el.fieldName,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editedItem[el.fieldName]",
                                                },
                                              })
                                            : _vm._e(),
                                          _vm.fieldType(el) === "INTEGER"
                                            ? _c("asyent-form-text", {
                                                attrs: {
                                                  label: _vm.ae$lng(
                                                    el.fieldName
                                                  ),
                                                  "view-mode": _vm.viewOnly,
                                                  "form-errors": _vm.formErrors,
                                                  "form-rules":
                                                    _vm.formFieldsRules,
                                                  "is-disabled-func": function (
                                                    _
                                                  ) {
                                                    return (
                                                      _vm.isUpdate ||
                                                      _vm.isDelete
                                                    )
                                                  },
                                                  props: {},
                                                  type: "number",
                                                  name: el.fieldName,
                                                },
                                                model: {
                                                  value:
                                                    _vm.editedItem[
                                                      el.fieldName
                                                    ],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.editedItem,
                                                      el.fieldName,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editedItem[el.fieldName]",
                                                },
                                              })
                                            : _vm._e(),
                                          _vm.fieldType(el) === "DECIMAL"
                                            ? _c("asyent-form-text", {
                                                attrs: {
                                                  label: _vm.ae$lng(
                                                    el.fieldName
                                                  ),
                                                  "view-mode": _vm.viewOnly,
                                                  "form-errors": _vm.formErrors,
                                                  "form-rules":
                                                    _vm.formFieldsRules,
                                                  "is-disabled-func": function (
                                                    _
                                                  ) {
                                                    return (
                                                      _vm.isUpdate ||
                                                      _vm.isDelete
                                                    )
                                                  },
                                                  props: {},
                                                  type: "number",
                                                  name: el.fieldName,
                                                },
                                                model: {
                                                  value:
                                                    _vm.editedItem[
                                                      el.fieldName
                                                    ],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.editedItem,
                                                      el.fieldName,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editedItem[el.fieldName]",
                                                },
                                              })
                                            : _vm._e(),
                                          _vm.fieldType(el) === "DATE"
                                            ? _c("asyent-form-date", {
                                                attrs: {
                                                  label: _vm.ae$lng(
                                                    el.fieldName
                                                  ),
                                                  "view-mode": _vm.viewOnly,
                                                  "form-errors": _vm.formErrors,
                                                  "form-rules":
                                                    _vm.formFieldsRules,
                                                  "is-disabled-func": function (
                                                    _
                                                  ) {
                                                    return (
                                                      _vm.isUpdate ||
                                                      _vm.isDelete
                                                    )
                                                  },
                                                  props: {},
                                                  name: el.fieldName,
                                                },
                                                model: {
                                                  value:
                                                    _vm.editedItem[
                                                      el.fieldName
                                                    ],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.editedItem,
                                                      el.fieldName,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editedItem[el.fieldName]",
                                                },
                                              })
                                            : _vm._e(),
                                          _vm.fieldType(el) === "DATETIME"
                                            ? _c("asyent-form-date", {
                                                attrs: {
                                                  label: _vm.ae$lng(
                                                    el.fieldName
                                                  ),
                                                  "view-mode": _vm.viewOnly,
                                                  "form-errors": _vm.formErrors,
                                                  "form-rules":
                                                    _vm.formFieldsRules,
                                                  "is-disabled-func": function (
                                                    _
                                                  ) {
                                                    return (
                                                      _vm.isUpdate ||
                                                      _vm.isDelete
                                                    )
                                                  },
                                                  props: {},
                                                  type: "datetime-local",
                                                  name: el.fieldName,
                                                },
                                                model: {
                                                  value:
                                                    _vm.editedItem[
                                                      el.fieldName
                                                    ],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.editedItem,
                                                      el.fieldName,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editedItem[el.fieldName]",
                                                },
                                              })
                                            : _vm._e(),
                                          _vm.fieldType(el) === "TIME"
                                            ? _c("asyent-form-text", {
                                                attrs: {
                                                  label: _vm.ae$lng(
                                                    el.fieldName
                                                  ),
                                                  "view-mode": _vm.viewOnly,
                                                  "form-errors": _vm.formErrors,
                                                  "form-rules":
                                                    _vm.formFieldsRules,
                                                  "is-disabled-func": function (
                                                    _
                                                  ) {
                                                    return (
                                                      _vm.isUpdate ||
                                                      _vm.isDelete
                                                    )
                                                  },
                                                  props: {},
                                                  type: "time",
                                                  name: el.fieldName,
                                                },
                                                model: {
                                                  value:
                                                    _vm.editedItem[
                                                      el.fieldName
                                                    ],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.editedItem,
                                                      el.fieldName,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editedItem[el.fieldName]",
                                                },
                                              })
                                            : _vm._e(),
                                          _vm.fieldType(el) === "ENUMERATION"
                                            ? _c("asyent-form-select", {
                                                attrs: {
                                                  label: _vm.ae$lng(
                                                    el.fieldName
                                                  ),
                                                  "view-mode": _vm.viewOnly,
                                                  "form-errors": _vm.formErrors,
                                                  "form-rules":
                                                    _vm.formFieldsRules,
                                                  "is-disabled-func": function (
                                                    _
                                                  ) {
                                                    return (
                                                      _vm.isUpdate ||
                                                      _vm.isDelete
                                                    )
                                                  },
                                                  props: {},
                                                  name: el.fieldName,
                                                  "items-static": "",
                                                  "drop-down": "",
                                                  "items-reload": function () {
                                                    return _vm.enumReload(el)
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.editedItem[
                                                      el.fieldName
                                                    ],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.editedItem,
                                                      el.fieldName,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editedItem[el.fieldName]",
                                                },
                                              })
                                            : _vm._e(),
                                          _vm.fieldType(el) === "TEXT"
                                            ? _c("asyent-form-text", {
                                                attrs: {
                                                  label: _vm.ae$lng(
                                                    el.fieldName
                                                  ),
                                                  "view-mode": _vm.viewOnly,
                                                  "form-errors": _vm.formErrors,
                                                  "form-rules":
                                                    _vm.formFieldsRules,
                                                  "is-disabled-func": function (
                                                    _
                                                  ) {
                                                    return (
                                                      _vm.isUpdate ||
                                                      _vm.isDelete
                                                    )
                                                  },
                                                  props: {},
                                                  name: el.fieldName,
                                                },
                                                model: {
                                                  value:
                                                    _vm.editedItem[
                                                      el.fieldName
                                                    ],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.editedItem,
                                                      el.fieldName,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editedItem[el.fieldName]",
                                                },
                                              })
                                            : _vm._e(),
                                          _vm.fieldType(el) === "CATALOG"
                                            ? _c("asyent-form-catalog", {
                                                attrs: {
                                                  label: _vm.ae$lng(
                                                    el.fieldName
                                                  ),
                                                  "view-mode": _vm.viewOnly,
                                                  "form-errors": _vm.formErrors,
                                                  "form-rules":
                                                    _vm.formFieldsRules,
                                                  "is-disabled-func": function (
                                                    _
                                                  ) {
                                                    return (
                                                      _vm.isUpdate ||
                                                      _vm.isDelete
                                                    )
                                                  },
                                                  props: {},
                                                  name: el.fieldName,
                                                  "service-name":
                                                    _vm.refCatalogProps(el)
                                                      .service,
                                                  catalog:
                                                    _vm.refCatalogProps(el)
                                                      .identifier,
                                                  "item-value":
                                                    _vm.refCatalogProps(el)
                                                      .code,
                                                  "item-text":
                                                    _vm.refCatalogProps(el)
                                                      .text,
                                                  "show-item-code":
                                                    _vm.refCatalogProps(el)
                                                      .show_code,
                                                  "show-item-avatar":
                                                    _vm.refCatalogProps(el)
                                                      .show_avatar,
                                                },
                                                model: {
                                                  value:
                                                    _vm.editedItem[
                                                      el.fieldName
                                                    ],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.editedItem,
                                                      el.fieldName,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editedItem[el.fieldName]",
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    _vm._l(_vm.segmentsValidity, function (el) {
                                      return _c(
                                        "v-col",
                                        {
                                          key: el.fieldName,
                                          attrs: {
                                            cols: "12",
                                            md: "6",
                                            lg: "4",
                                          },
                                        },
                                        [
                                          _vm.fieldType(el) === "DATETIME"
                                            ? _c("asyent-form-date", {
                                                attrs: {
                                                  label: _vm.ae$prefixLng(
                                                    "catalog.validity",
                                                    el.fieldName
                                                  ),
                                                  "view-mode": _vm.viewOnly,
                                                  "form-errors": _vm.formErrors,
                                                  "form-rules":
                                                    _vm.formFieldsRules,
                                                  "is-disabled-func": function (
                                                    _
                                                  ) {
                                                    return (
                                                      el.fieldName ===
                                                        _vm.startTime &&
                                                      (_vm.isUpdate ||
                                                        _vm.isDelete)
                                                    )
                                                  },
                                                  props: {},
                                                  type: "datetime-local",
                                                  name: el.fieldName,
                                                },
                                                model: {
                                                  value:
                                                    _vm.editedItem[
                                                      el.fieldName
                                                    ],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.editedItem,
                                                      el.fieldName,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editedItem[el.fieldName]",
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.segmentsNormalVisible &&
                      _vm.segmentsNormalVisible.length > 0
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-card",
                                { attrs: { color: "cardDefault", flat: "" } },
                                [
                                  !_vm.viewOnly
                                    ? _c(
                                        "v-card-title",
                                        {
                                          staticClass:
                                            "pa-0 ma-0 pb-4 subtitle-2 font-weight-bold",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.ae$prefixLng(
                                                  "catalog",
                                                  "dialog.sections.general"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-card-text",
                                    { staticClass: "pa-0 ma-0 pr-4" },
                                    [
                                      !_vm.viewOnly
                                        ? _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c("v-divider", {
                                                    staticClass: "pt-2 pb-2",
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-row",
                                        _vm._l(
                                          _vm.segmentsNormalVisible,
                                          function (el) {
                                            return _c(
                                              "v-col",
                                              {
                                                key: el.fieldName,
                                                attrs: {
                                                  cols: "12",
                                                  md: "6",
                                                  lg: "4",
                                                },
                                              },
                                              [
                                                _vm.fieldType(el) === "BOOLEAN"
                                                  ? _c("asyent-form-boolean", {
                                                      attrs: {
                                                        label: _vm.ae$lng(
                                                          el.fieldName
                                                        ),
                                                        "view-mode":
                                                          _vm.viewOnly,
                                                        "form-errors":
                                                          _vm.formErrors,
                                                        "form-rules":
                                                          _vm.formFieldsRules,
                                                        "is-disabled-func":
                                                          _vm.disabled,
                                                        props: {},
                                                        "false-value": false,
                                                        "true-value": true,
                                                        name: el.fieldName,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.editedItem[
                                                            el.fieldName
                                                          ],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.editedItem,
                                                            el.fieldName,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "editedItem[el.fieldName]",
                                                      },
                                                    })
                                                  : _vm._e(),
                                                _vm.fieldType(el) === "INTEGER"
                                                  ? _c("asyent-form-text", {
                                                      attrs: {
                                                        label: _vm.ae$lng(
                                                          el.fieldName
                                                        ),
                                                        "view-mode":
                                                          _vm.viewOnly,
                                                        "form-errors":
                                                          _vm.formErrors,
                                                        "form-rules":
                                                          _vm.formFieldsRules,
                                                        "is-disabled-func":
                                                          _vm.disabled,
                                                        props: {},
                                                        type: "number",
                                                        name: el.fieldName,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.editedItem[
                                                            el.fieldName
                                                          ],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.editedItem,
                                                            el.fieldName,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "editedItem[el.fieldName]",
                                                      },
                                                    })
                                                  : _vm._e(),
                                                _vm.fieldType(el) === "DECIMAL"
                                                  ? _c("asyent-form-text", {
                                                      attrs: {
                                                        label: _vm.ae$lng(
                                                          el.fieldName
                                                        ),
                                                        "view-mode":
                                                          _vm.viewOnly,
                                                        "form-errors":
                                                          _vm.formErrors,
                                                        "form-rules":
                                                          _vm.formFieldsRules,
                                                        "is-disabled-func":
                                                          _vm.disabled,
                                                        props: {},
                                                        type: "number",
                                                        name: el.fieldName,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.editedItem[
                                                            el.fieldName
                                                          ],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.editedItem,
                                                            el.fieldName,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "editedItem[el.fieldName]",
                                                      },
                                                    })
                                                  : _vm._e(),
                                                _vm.fieldType(el) === "DATE"
                                                  ? _c("asyent-form-date", {
                                                      attrs: {
                                                        label: _vm.ae$lng(
                                                          el.fieldName
                                                        ),
                                                        "view-mode":
                                                          _vm.viewOnly,
                                                        "form-errors":
                                                          _vm.formErrors,
                                                        "form-rules":
                                                          _vm.formFieldsRules,
                                                        "is-disabled-func":
                                                          _vm.disabled,
                                                        props: {},
                                                        name: el.fieldName,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.editedItem[
                                                            el.fieldName
                                                          ],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.editedItem,
                                                            el.fieldName,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "editedItem[el.fieldName]",
                                                      },
                                                    })
                                                  : _vm._e(),
                                                _vm.fieldType(el) === "DATETIME"
                                                  ? _c("asyent-form-text", {
                                                      attrs: {
                                                        label: _vm.ae$lng(
                                                          el.fieldName
                                                        ),
                                                        "view-mode":
                                                          _vm.viewOnly,
                                                        "form-errors":
                                                          _vm.formErrors,
                                                        "form-rules":
                                                          _vm.formFieldsRules,
                                                        "is-disabled-func":
                                                          _vm.disabled,
                                                        props: {},
                                                        type: "datetime-local",
                                                        name: el.fieldName,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.editedItem[
                                                            el.fieldName
                                                          ],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.editedItem,
                                                            el.fieldName,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "editedItem[el.fieldName]",
                                                      },
                                                    })
                                                  : _vm._e(),
                                                _vm.fieldType(el) === "TIME"
                                                  ? _c("asyent-form-text", {
                                                      attrs: {
                                                        label: _vm.ae$lng(
                                                          el.fieldName
                                                        ),
                                                        "view-mode":
                                                          _vm.viewOnly,
                                                        "form-errors":
                                                          _vm.formErrors,
                                                        "form-rules":
                                                          _vm.formFieldsRules,
                                                        "is-disabled-func":
                                                          _vm.disabled,
                                                        props: {},
                                                        type: "time",
                                                        name: el.fieldName,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.editedItem[
                                                            el.fieldName
                                                          ],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.editedItem,
                                                            el.fieldName,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "editedItem[el.fieldName]",
                                                      },
                                                    })
                                                  : _vm._e(),
                                                _vm.fieldType(el) ===
                                                "ENUMERATION"
                                                  ? _c("asyent-form-select", {
                                                      attrs: {
                                                        label: _vm.ae$lng(
                                                          el.fieldName
                                                        ),
                                                        "view-mode":
                                                          _vm.viewOnly,
                                                        "form-errors":
                                                          _vm.formErrors,
                                                        "form-rules":
                                                          _vm.formFieldsRules,
                                                        "is-disabled-func":
                                                          _vm.disabled,
                                                        props: {},
                                                        name: el.fieldName,
                                                        "items-static": "",
                                                        "drop-down": "",
                                                        "items-reload":
                                                          function () {
                                                            return _vm.enumReload(
                                                              el
                                                            )
                                                          },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.editedItem[
                                                            el.fieldName
                                                          ],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.editedItem,
                                                            el.fieldName,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "editedItem[el.fieldName]",
                                                      },
                                                    })
                                                  : _vm._e(),
                                                _vm.fieldType(el) === "TEXT"
                                                  ? _c("asyent-form-text", {
                                                      attrs: {
                                                        label: _vm.ae$lng(
                                                          el.fieldName
                                                        ),
                                                        "view-mode":
                                                          _vm.viewOnly,
                                                        "form-errors":
                                                          _vm.formErrors,
                                                        "form-rules":
                                                          _vm.formFieldsRules,
                                                        "is-disabled-func":
                                                          _vm.disabled,
                                                        props: {},
                                                        name: el.fieldName,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.editedItem[
                                                            el.fieldName
                                                          ],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.editedItem,
                                                            el.fieldName,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "editedItem[el.fieldName]",
                                                      },
                                                    })
                                                  : _vm._e(),
                                                _vm.fieldType(el) === "CATALOG"
                                                  ? _c("asyent-form-catalog", {
                                                      attrs: {
                                                        label: _vm.ae$lng(
                                                          el.fieldName
                                                        ),
                                                        "view-mode":
                                                          _vm.viewOnly,
                                                        "form-errors":
                                                          _vm.formErrors,
                                                        "form-rules":
                                                          _vm.formFieldsRules,
                                                        "is-disabled-func":
                                                          _vm.disabled,
                                                        props: {},
                                                        name: el.fieldName,
                                                        "service-name":
                                                          _vm.refCatalogProps(
                                                            el
                                                          ).service,
                                                        catalog:
                                                          _vm.refCatalogProps(
                                                            el
                                                          ).identifier,
                                                        "item-value":
                                                          _vm.refCatalogProps(
                                                            el
                                                          ).code,
                                                        "item-text":
                                                          _vm.refCatalogProps(
                                                            el
                                                          ).text,
                                                        "show-item-code":
                                                          _vm.refCatalogProps(
                                                            el
                                                          ).show_code,
                                                        "show-item-avatar":
                                                          _vm.refCatalogProps(
                                                            el
                                                          ).show_avatar,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.editedItem[
                                                            el.fieldName
                                                          ],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.editedItem,
                                                            el.fieldName,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "editedItem[el.fieldName]",
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("asyent-search", {
        attrs: {
          "filter-dialog": _vm.filterDialog,
          filters: _vm.filters,
          headers: _vm.headers,
          "reload-counter": _vm.reloadCounter,
          "row-actions": _vm.rowActions,
          "row-method": _vm.rowMethod,
          "search-method": _vm.search,
          criteria: _vm.criteria,
          "no-height": _vm.widget,
          "export-counter": _vm.exportCounter,
          "export-file-name": _vm.identifier,
          "items-per-page-options": [15, 30, 50, 100, 500],
          "sort-by": _vm.ids.concat([_vm.startTime]),
        },
        on: {
          "filter-dialog": function ($event) {
            _vm.filterDialog = $event
          },
          "update-records": function ($event) {
            _vm.records = $event
          },
        },
        scopedSlots: _vm._u(
          [
            _vm._l(_vm.slotsNames, function (name) {
              return {
                key: name,
                fn: function (ref) {
                  var item = ref.item
                  return [
                    !item[name.replace("item.", "")]
                      ? _c("span", { key: name }, [_vm._v(" ... ")])
                      : name === "item.startTime" || name === "item.endTime"
                      ? _c("span", { key: name }, [
                          _c("u", [
                            _vm._v(
                              _vm._s(
                                _vm.ae$formatDateTime(
                                  item[name.replace("item.", "")]
                                )
                              )
                            ),
                          ]),
                        ])
                      : _vm.ids.indexOf(name.replace("item.", "")) > -1
                      ? _c("span", { key: name }, [
                          _c("b", [
                            _vm._v(_vm._s(item[name.replace("item.", "")])),
                          ]),
                        ])
                      : _c("span", { key: name }, [
                          _vm._v(
                            " " + _vm._s(item[name.replace("item.", "")]) + " "
                          ),
                        ]),
                  ]
                },
              }
            }),
            {
              key: "item.actions",
              fn: function (ref) {
                var item = ref.item
                return [
                  _c(
                    "v-menu",
                    {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function (ref) {
                              var on = ref.on
                              return [
                                _c("v-icon", _vm._g({}, on), [
                                  _vm._v(" mdi-dots-vertical"),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _c(
                        "v-list",
                        { attrs: { width: "200px", dense: "" } },
                        [
                          _c("asyent-button", {
                            attrs: {
                              "button-icon": "mdi-eye",
                              "button-tooltip": _vm.ae$prefixLng(
                                "catalog",
                                "dialog.button.view"
                              ),
                              dark: "",
                              small: "",
                              "as-list-item": "",
                              "dense-list": "",
                              "as-avatar": "",
                              icon: "",
                            },
                            on: {
                              confirmed: function ($event) {
                                return _vm.viewItem(item)
                              },
                            },
                          }),
                          _c("asyent-button", {
                            attrs: {
                              "button-icon": "mdi-pencil",
                              "button-tooltip": _vm.ae$prefixLng(
                                "catalog",
                                "dialog.button.edit"
                              ),
                              dark: "",
                              small: "",
                              "as-list-item": "",
                              "dense-list": "",
                              "as-avatar": "",
                              icon: "",
                            },
                            on: {
                              confirmed: function ($event) {
                                return _vm.editItem(item)
                              },
                            },
                          }),
                          _c("asyent-button", {
                            attrs: {
                              "button-icon": "mdi-delete",
                              "button-tooltip": _vm.ae$prefixLng(
                                "catalog",
                                "dialog.button.delete"
                              ),
                              dark: "",
                              icon: "",
                              loading: _vm.isDeleting,
                              "as-list-item": "",
                              "dense-list": "",
                              "as-avatar": "",
                              small: "",
                              "requires-confirmation": "",
                            },
                            on: {
                              confirmed: function ($event) {
                                return _vm.deleteItem(item)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: "item.actions2",
              fn: function (ref) {
                var item = ref.item
                return [
                  _c(
                    "v-menu",
                    {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function (ref) {
                              var on = ref.on
                              return [
                                _c("v-icon", _vm._g({}, on), [
                                  _vm._v(" mdi-dots-vertical"),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _c(
                        "v-list",
                        { attrs: { width: "200px", dense: "" } },
                        [
                          _c("asyent-button", {
                            attrs: {
                              "button-icon": "mdi-eye",
                              "button-tooltip": _vm.ae$prefixLng(
                                "catalog",
                                "dialog.button.view"
                              ),
                              dark: "",
                              small: "",
                              "as-list-item": "",
                              "dense-list": "",
                              "as-avatar": "",
                              icon: "",
                            },
                            on: {
                              confirmed: function ($event) {
                                return _vm.viewItem(item)
                              },
                            },
                          }),
                          _c("asyent-button", {
                            attrs: {
                              "button-icon": "mdi-pencil",
                              "button-tooltip": _vm.ae$prefixLng(
                                "catalog",
                                "dialog.button.edit"
                              ),
                              dark: "",
                              small: "",
                              "as-list-item": "",
                              "dense-list": "",
                              "as-avatar": "",
                              icon: "",
                            },
                            on: {
                              confirmed: function ($event) {
                                return _vm.editItem(item)
                              },
                            },
                          }),
                          _c("asyent-button", {
                            attrs: {
                              "button-icon": "mdi-delete",
                              "button-tooltip": _vm.ae$prefixLng(
                                "catalog",
                                "dialog.button.delete"
                              ),
                              dark: "",
                              icon: "",
                              loading: _vm.isDeleting,
                              "as-list-item": "",
                              "dense-list": "",
                              "as-avatar": "",
                              small: "",
                              "requires-confirmation": "",
                            },
                            on: {
                              confirmed: function ($event) {
                                return _vm.deleteItem(item)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
      _c("div", { staticClass: "bottom-space" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }